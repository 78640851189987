const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\RoutesInput.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {
  formatDistance,
  formatRelativeDates,
  useTranslation,
  useUrlParam,
} from "@opendash/core";
import { Button, Drawer } from "antd";
import React from "react";
import { RoutesMap } from "..";













export function RoutesInput({
  disabled,
  loading,
  routes,
  places,
  value = 0,
  label,
  urlParam,
  style,
}) {
  const t = useTranslation();

  const [open, setOpen] = useUrlParam(urlParam, false, "json");

  const route = _optionalChain([routes, 'optionalAccess', _ => _[value]]);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Button, {
        size: "large",
        style: style,
        loading: loading,
        disabled: disabled,
        children: route ? getName(route) : t("rs:ride.route_select"),
        title: label,
        onClick: (e) => setOpen(true), __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      )

      , React.createElement(Drawer, {
        visible: open,
        title: label,
        placement: "bottom",
        height: window.innerHeight,
        bodyStyle: { padding: 0 },
        onClose: () => {
          setOpen(false);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
      
        , React.createElement(RoutesMap, { routes: routes, route: route, places: places, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}} )
      )
    )
  );
}

function getName(route) {
  return (
    formatDistance(route.distance * 1000) +
    " " +
    formatRelativeDates(Date.now() + route.time, Date.now())
  );
}
