const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\MessengerChatInput.tsx";import React from "react";
import {

  MessengerChatInputDefault,
  MessengerChatInputSimple,
  getUserProfile,
} from "..";

export function MessengerChatInput({ chat }) {
  const isSimple = getUserProfile().accessibilityUsePredefinedMessages;

  if (!chat) {
    return null;
  }

  if (isSimple) {
    return React.createElement(MessengerChatInputSimple, { chat: chat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}} );
  }

  return React.createElement(MessengerChatInputDefault, { chat: chat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}} );
}
