import Parse from "parse";
import React from "react";








export function useParseFunction(
  name,
  params,
  dependency
) {
  const [state, setState] = React.useState({
    result: undefined,
    loading: true,
    error: undefined,
    reload: fetch,
  });

  async function fetch() {
    if (!name) {
      return;
    }

    Parse.Cloud.run(name, params).then(
      (response) => {
        setState((currentState) => ({
          ...currentState,
          result: response,
          error: undefined,
          loading: false,
        }));
      },
      (error) => {
        setState((s) => ({
          ...s,
          result: undefined,
          error,
          loading: false,
        }));
      }
    );
  }

  React.useEffect(() => {
    setState({
      result: undefined,
      loading: true,
      error: undefined,
      reload: fetch,
    });

    fetch();
  }, [name, ...dependency]);

  return state;
}
