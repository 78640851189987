const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\UserPredefinedSearch.tsx";import { useUrlParam } from "@opendash/core";
import React from "react";
import { UserPredefinedSearchCreate, UserPredefinedSearchList } from "..";

export function UserPredefinedSearch({}) {
  const [view, setView] = useUrlParam("view", null);

  if (view === "list") {
    return React.createElement(UserPredefinedSearchList, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 9}} );
  }

  if (view === "create") {
    return React.createElement(UserPredefinedSearchCreate, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 13}} );
  }

  return React.createElement(UserPredefinedSearchList, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 16}} );
}
