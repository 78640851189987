const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\LocationSearch.tsx";import { useTranslation } from "@opendash/core";
import { Divider, Drawer, Input } from "antd";
import React from "react";
import {
  Location,
  LocationList,
  useCloudCodeGeoCoding,
  useCurrentLocation,
  useParseList,
} from "..";








export const LocationSearch = React.memo(
  ({ visible, label, onChange, onClose }) => {
    const t = useTranslation();
    const [query, setQuery] = React.useState("");

    const {
      loading: recentLoading,
      error: recentError,
      result: recentLocations,
    } = useParseList(visible ? Location : null);

    const { loading, error, result } = useCloudCodeGeoCoding(query);

    const currentLocation = useCurrentLocation();

    const selectLocation = (item) => {
      if (!item.id) {
        const recent = recentLocations.find(
          (loc) =>
            item.get("title") === loc.get("title") &&
            item.get("description") === loc.get("description")
        );

        if (recent) {
          item = recent;
        }
      }

      item.save().then(() => {
        onChange(item);
        onClose();
      });
    };

    return (
      React.createElement(Drawer, {
        visible: visible,
        title: label,
        placement: "bottom",
        height: 400,
        onClose: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
      
        , React.createElement(Input.Search, {
          loading: query && loading,
          onChange: (e) => {
            setQuery(e.target.value);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
        )

        , !query && currentLocation && (
          React.createElement(React.Fragment, null
            , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}, t("rs:locations.current"))
            , React.createElement(LocationList, {
              loading: false,
              locations: [currentLocation],
              onChange: selectLocation, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
            )
          )
        )

        , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
          , t(query ? "rs:locations.result" : "rs:locations.recent")
        )

        , React.createElement(LocationList, {
          loading: query ? loading : recentLoading,
          locations: query ? result : recentLocations,
          onChange: selectLocation, __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
        )
      )
    );
  }
);
