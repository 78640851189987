const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\LocationInput.tsx";import { useUrlParam } from "@opendash/core";
import { Button } from "antd";
import React from "react";
import { LocationSearch } from "..";









export function LocationInput({
  location,
  setLocation,
  urlParam,
  label,
  style,
}) {
  const [open, setOpen] = useUrlParam(urlParam, false, "json");

  const actualStyle = React.useMemo(() => {
    return Object.assign({}, style || {}, {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    });
  }, [style]);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Button, {
        size: "large",
        style: actualStyle,
        children: 
          location
            ? location.get("title") || location.get("description")
            : label
        ,
        title: label,
        onClick: (e) => setOpen(true), __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
      )

      , React.createElement(LocationSearch, {
        visible: open,
        label: label,
        onChange: (location) => {
          setLocation(location);
        },
        onClose: () => {
          setOpen(false);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
      )
    )
  );
}
