
import "./serivce-worker";

export const app = {};

init().catch((error) => {
  console.error(error);
});

async function init() {
  //   await initPushService();
}
