const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\AppLayout.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { getUserProfile, useChatsNewCount } from "..";

const Container = styled.div`
  width: 100%;
  padding-bottom: 80px;

  user-select: none;
`;

const NavigationContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;

  width: 100%;
  height: 60px;

  background: #f0f2f5;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);

  z-index: 99;

  a {
    display: block;
    background: white;
    width: 100%;
    text-align: center;
    color: inherit;
    height: 60px;
    padding: 6px 0;

    &.active {
      border-bottom: 3px solid #1890ff;
      color: #1890ff;
      font-weight: bold;
    }
  }

  @supports (bottom: constant(safe-area-inset-bottom)) {
    padding-bottom: constant(safe-area-inset-bottom);
    height: calc(constant(safe-area-inset-bottom) + 60px);
  }
`;

const IconHolder = styled.div`
  font-size: 20px;
  line-height: 26px;
`;

const Label = styled.div`
  font-size: 12px;
  line-height: 20px;
`;

const BodyContainer = styled.div``;

export function AppLayout({ children }) {
  const t = useTranslation();

  const messages = useChatsNewCount();

  const offerDisabled = _optionalChain([getUserProfile, 'call', _ => _(), 'optionalAccess', _2 => _2.offerDisabled]);
  const homeDisabled = _optionalChain([getUserProfile, 'call', _3 => _3(), 'optionalAccess', _4 => _4.homeDisabled]);

  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
      , React.createElement(NavigationContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
        , !homeDisabled && (
          React.createElement(NavLink, { to: "home", __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
            , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
              , React.createElement(Icon, { icon: "ai:home", __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}} )
            )
            , React.createElement(Label, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}, t("rs:navigation.home"))
          )
        )

        , React.createElement(NavLink, { to: "search", __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
          , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
            , React.createElement(Icon, { icon: "ai:search", __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}} )
          )
          , React.createElement(Label, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}, t("rs:navigation.search"))
        )

        , !offerDisabled && (
          React.createElement(NavLink, { to: "offer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
            , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
              , React.createElement(Icon, { icon: "ai:car", __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}} )
            )
            , React.createElement(Label, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}, t("rs:navigation.offer"))
          )
        )

        , React.createElement(NavLink, { to: "messenger", __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
          , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
            , React.createElement(Icon, { icon: "ai:message", __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}} )
          )
          , React.createElement(Label, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}, t("rs:navigation.messenger"))
        )

        , React.createElement(NavLink, { to: "profile", __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}
          , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}
            , React.createElement(Icon, { icon: "ai:setting", __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}} )
          )
          , React.createElement(Label, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}, t("rs:navigation.settings"))
        )
      )
      , React.createElement(BodyContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}, children)
    )
  );
}
