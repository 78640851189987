const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\MessengerChatInputSimple.tsx";import { useTranslation } from "@opendash/core";
import { Button, Drawer, message as m } from "antd";
import * as Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React from "react";
import { Message, QuickMessage } from "..";

const ButtonStyle = {
  width: "100%",
};

const ButtonMBStyle = {
  width: "100%",
  marginBottom: 24,
};

export function MessengerChatInputSimple({ chat }) {
  const t = useTranslation();
  const [open, setOpen] = React.useState(false);

  const query = React.useMemo(() => new Parse.Query(QuickMessage), []);
  const { result: quickmessages } = useParseQuery(query);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Button, {
        type: "primary",
        style: ButtonStyle,
        children: t("rs:messenger.quickmessages.action"),
        onClick: (e) => {
          setOpen(true);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
      )
      , React.createElement(Drawer, {
        title: t("rs:messenger.quickmessages.title"),
        visible: open,
        onClose: () => setOpen(false),
        placement: "bottom",
        height: 55 + 24 + quickmessages.length * 56, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
      
        , quickmessages.map((quickmessage, i, a) => (
          React.createElement(Button, {
            key: quickmessage.get("label"),
            type: "primary",
            style: a.length - 1 > i ? ButtonMBStyle : ButtonStyle,
            children: quickmessage.get("label"),
            onClick: (e) => {
              const msg = new Message({
                chat,
                message: quickmessage.get("message"),
              });

              const hideLoading = m.loading(
                t("rs:messenger.message.send_loading"),
                0
              );

              setOpen(false);

              msg.save().then(
                () => {
                  hideLoading();
                },
                (error) => {
                  hideLoading();
                  m.error(t("rs:messenger.message.send_error"));
                  console.error(error);
                }
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
          )
        ))
      )
    )
  );
}
