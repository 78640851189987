import { usePromise } from "@opendash/core";
import moment from "moment";
import Parse from "parse";
import {
  fetchPublicTransport,

  useCurrentPosition,
} from "..";

export function usePublicTransportNearby() {
  const [lat, long] = useCurrentPosition();

  return usePromise(() => {
    const distance =
      Parse.User.current().get("rsProfile").get("searchBuffer") || 1000;

    return fetchPublicTransportNearby(lat, long, distance);
  }, [lat, long]);
}

async function fetchPublicTransportNearby(
  lat,
  long,
  distance = 500
) {
  const nearbyStops = await fetchPublicTransport("/stops/nearby", {
    latitude: lat.toString(),
    longitude: long.toString(),
    distance: distance.toString(),
    language: "de",
  });

  const nearbyDepartures = await Promise.all(
    nearbyStops.map((stop) =>
      fetchPublicTransport(`/stops/${stop.id}/departures`, {
        when: new Date().toJSON(),
        language: "de",
      })
    )
  );

  const result = nearbyDepartures
    .flat()
    .sort((a, b) => a.when - b.when)
    .filter((v, i) => i < 3)
    .map((departure) => {
      const when = moment(departure.when).format("HH:mm");

      return {
        title: departure.line.name + " um " + when,
        description: [departure.stop.name].join(" → "),
        stops: [],
      };
    });

  return result;
}
