import { useParseFunction } from "..";







export function useCloudCodeTenantOptions() {
  return useParseFunction("ride-sharing-tenants", {}, []);
}
