import moment from "moment";
import Parse from "parse";
import React from "react";
import {
  Offer,
  useCurrentPosition,
  useRideSearchDate,
  useRideSearchTime,
} from "..";

export function useRideSearchLocal() {
  const [state, setState] = React.useState([]);

  const [lat, long] = useCurrentPosition();
  const [time] = useRideSearchTime();
  const [date] = useRideSearchDate();

  React.useEffect(() => {
    if (lat && long) {
      fetchOffers({
        start_latitude: lat,
        start_longitude: long,
        weekday: moment(date).weekday(),
        time,
        date,
      }).then(
        (result) => {
          setState(result);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [lat, long]);

  return state;
}

async function fetchOffers(params) {
  const result = await Parse.Cloud.run("rsSearchLocal", params);

  const offers = result.offers.map((id) =>
    Offer.createWithoutData(id)
  );

  await Offer.fetchAll(offers, {});

  for (const offer of offers) {
    const user = offer.get("user");

    await user.fetch();

    const profile = user.get("rsProfile");

    await profile.fetch();
  }

  return offers;
}
