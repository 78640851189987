import Parse from "parse";













export class Location extends Parse.Object {
  constructor(data) {
    super("RideSharingLocation", data );
  }
}

Parse.Object.registerSubclass("RideSharingLocation", Location);
