 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useChats } from "..";

export function useChatsNewCount() {
  const chats = useChats();

  const newChats = chats.filter(
    (chat) => _optionalChain([chat, 'access', _ => _.get, 'call', _2 => _2("lastMessageUser"), 'optionalAccess', _3 => _3.id]) !== _optionalChain([Parse, 'access', _4 => _4.User, 'access', _5 => _5.current, 'call', _6 => _6(), 'optionalAccess', _7 => _7.id])
  );

  return newChats.length;
}
