import { createContext } from "react";


export const RideSharingStateContext = createContext(
  {
    error: null,
    loading: true,
    user: null,
    profile: null,
    chats: [],
    messages: [],
  }
);
