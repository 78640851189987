const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\FormElement.tsx";
import React from "react";
import styled from "styled-components";


const Label = styled.label`
  display: block;
  margin-bottom: 24px;
`;

const LabelDiv = styled.div`
  display: block;
  margin-bottom: 24px;
`;

const LabelText = styled.span`
  display: block;
  width: 100%;
  text-align: center;
  font-size: 10px;
  line-height: 10px;
  margin-bottom: 6px;
  text-transform: uppercase;
  letter-spacing: 3px;
`;

export function FormElement({ label, children, useLabel = true }) {
  const Container = useLabel ? Label : LabelDiv;

  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
      , React.createElement(LabelText, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}, label)
      , children
    )
  );
}
