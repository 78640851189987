const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\AppRoute.tsx";import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  AppData,
  AppLayout,
  HomeRoute,
  IntroRoute,
  MessengerRoute,
  RideOfferRoute,
  RideSearchRoute,
  UserSettingsRoute,
} from "..";

export function AppRoute({ uri }) {
  return (
    React.createElement(AppLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
      , React.createElement(AppData, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
        , React.createElement(Routes, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
          , React.createElement(Route, { path: "home", element: React.createElement(HomeRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} )
          , React.createElement(Route, { path: "search", element: React.createElement(RideSearchRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 20}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}} )
          , React.createElement(Route, { path: "offer", element: React.createElement(RideOfferRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 21}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}} )
          , React.createElement(Route, { path: "messenger", element: React.createElement(MessengerRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 22}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}} )
          , React.createElement(Route, { path: "profile", element: React.createElement(UserSettingsRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 23}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}} )
          , React.createElement(Route, { path: "intro", element: React.createElement(IntroRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} )
          /* <Route path="*" element={<DefaultRoute />} /> */
        )
      )
    )
  );
}

function DefaultRoute({}) {
  const navigate = useNavigate();
  navigate("search");
  return null;
}
