//@ts-check

if (!window.localStorage.getItem("opendash:language")) {
  window.localStorage.setItem("opendash:language", '"de"');
}

import * as Parse from "parse";
import { init, StorageAdapterLS } from "@opendash/core";
import * as i18n from "@opendash/i18n";
import { registerIconPack } from "@opendash/icons";
import { ParsePlugin } from "@opendash/plugin-parse";
import { ParseAdminPlugin } from "@opendash/plugin-parse-admin";
import "antd/dist/antd.min.css";
import "./leaflet.config";
import "./parse.config";
import { AdminRoute, AppRoute, AuthSignupForm } from "./ride-sharing-app";

init("opendash", async (factory) => {
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/ai-outlined.json"));

  factory.ui.disableHeader();
  factory.ui.disableFooter();

  factory.ui.setAuthSignupForm(AuthSignupForm);

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());

  await factory.use(
    new ParsePlugin({
      async authPayloadMapper(payload) {
        console.log(payload);
        return {
          ...payload,
          tenant: new Parse.Object("OD3_Tenant", { id: payload.tenant }),
        };
      },
    })
  );

  await factory.use(
    new ParseAdminPlugin([
      {
        className: "_User",
        relation: ["username"],
        columns: [
          "name",
          "username",
          "email",
          "emailVerified",
          "tenant",
          "tenantVerified",
          "tenantAdmin",
          "tenantBanned",
          "updatedAt",
        ],
        create_fields: [
          "name",
          "username",
          "email",
          "password",
          "tenant",
          "tenantVerified",
          "tenantAdmin",
          "tenantBanned",
        ],
        edit_fields: [
          "name",
          "username",
          "email",
          "password",
          "tenant",
          "tenantVerified",
          "tenantAdmin",
          "tenantBanned",
        ],
      },
      {
        className: "OD3_Tenant",
        relation: ["label"],
        columns: ["label"],
        create_fields: ["label"],
        edit_fields: ["label"],
      },
      {
        className: "RideSharingPlace",
        relation: ["type", "name"],
        columns: [
          "type",
          "name",
          "icon",
          "location",
          "enabled",
          "highlight",
          "osm",
        ],
        create_fields: [
          "type",
          "name",
          "icon",
          "location",
          "enabled",
          "highlight",
        ],
        edit_fields: [
          "type",
          "name",
          "icon",
          "location",
          "enabled",
          "highlight",
        ],
      },
      {
        className: "RideSharingTaxiInfo",
        relation: ["name"],
        columns: ["name", "phone", "website", "location", "radius"],
        create_fields: ["name", "phone", "website", "location", "radius"],
        edit_fields: ["name", "phone", "website", "location", "radius"],
      },
      {
        className: "OD3_NavigationGroup",
        relation: ["label"],
        columns: ["icon", "label", "order", "tenant"],
        create_fields: ["icon", "label", "order", "tenant"],
        edit_fields: ["icon", "label", "order", "tenant"],
      },
      {
        className: "OD3_NavigationItem",
        relation: ["label"],
        columns: [
          "group",
          "place",
          "label",
          "link",
          "activeCondition",
          "routeCondition",
          "order",
          "icon",
          "color",
          "tenant",
        ],
        create_fields: [
          "group",
          "place",
          "label",
          "link",
          "activeCondition",
          "routeCondition",
          "order",
          "icon",
          "color",
          "tenant",
        ],
        edit_fields: [
          "group",
          "place",
          "label",
          "link",
          "activeCondition",
          "routeCondition",
          "order",
          "icon",
          "color",
          "tenant",
        ],
      },
    ])
  );

  // factory.registerLanguage("en", "English");
  factory.registerLanguage("de", "Deutsch");
  factory.registerLanguage("de_sie", "Deutsch (Sie)", "de", true);
  // factory.registerLanguage(
  //   "de_supported",
  //   "Unterstützte Kommunikation",
  //   "de_sie"
  // );
  // factory.registerLanguage(
  //   "de_accessible",
  //   "Reduzierte Oberfläche",
  //   "de_supported"
  // );

  factory.registerTranslationResolver(
    "de",
    "opendash",
    async () => await import("./translations/de/opendash")
  );

  factory.registerAntDesignTranslation("de", () =>
    import("antd/lib/locale/de_DE")
  );

  factory.registerTranslationResolver(
    "de",
    "rs",
    async () => await import("./translations/de/ride-sharing")
  );

  factory.registerTranslationResolver(
    "de",
    "parse-custom",
    async (language) => await import("./translations/de/parse-custom.json")
  );

  factory.registerTranslationResolver(
    "de_sie",
    "opendash",
    async () => await import("./translations/de_sie/opendash")
  );

  factory.registerTranslationResolver(
    "de_sie",
    "rs",
    async () => await import("./translations/de_sie/ride-sharing")
  );

  // Routing

  factory.registerRoute({
    path: "/",
    redirectPath: "/ride-sharing/home",
  });

  factory.registerRoute({
    path: "/ride-sharing/*",
    component: async () => ({ default: AppRoute }),
  });

  factory.registerRoute({
    path: "/admin/ride-sharing/*",
    component: async () => ({ default: AdminRoute }),
  });
}).then(async (app) => {
  console.log("init open.DASH");

  console.log(i18n.__debug());
});
