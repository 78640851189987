import React from "react";

export function useCurrentPosition() {
  const [state, setState] = React.useState([0, 0]);

  React.useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setState([position.coords.latitude, position.coords.longitude]);
      });
    }
  }, []);

  return state;
}
