import React from "react";
import Parse from "parse";
import { useParseQuery } from "parse-hooks";
import distance from "@turf/distance";
import { Place } from "..";









export function usePlacesNearLocation(
  location,
  baseQuery
) {
  const km =
    Math.ceil(
      Parse.User.current().get("rsProfile").get("searchBuffer")
    ) || 2;

  const query = React.useMemo(
    () =>
      location
        ? (baseQuery || new Parse.Query(Place))
            .equalTo("enabled", true)
            .withinKilometers("location", location.get("location"), km)
            .limit(4)
        : null,
    [location]
  );

  const request = useParseQuery(query);

  const distances = React.useMemo(() => {
    const result = {};

    for (const palce of request.result) {
      const a = location.get("location");
      const b = palce.get("location");

      result[palce.id] = Math.round(
        distance([a.latitude, a.longitude], [b.latitude, b.longitude], {
          units: "meters",
        })
      );
    }

    return result;
  }, [request.result]);

  const places = React.useMemo(() => {
    return [...request.result].sort((a, b) => {
      if (a.get("highlight") !== b.get("highlight")) {
        if (a.get("highlight")) {
          return -1;
        } else {
          return 1;
        }
      }

      return distances[a.id] - distances[b.id];
    });
  }, [request.result, distances]);

  return {
    places,
    distances: distances || {},
    loading: request.loading,
    error: request.error,
  };
}
