const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\LocationList.tsx";import { useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, List } from "antd";
import React from "react";








export const LocationList = React.memo(
  ({ loading, locations, onChange }) => {
    const t = useTranslation();

    return (
      React.createElement(List, {
        loading: loading,
        dataSource: locations,
        renderItem: (item) => (
          React.createElement(List.Item, {
            onClick: () => {
              onChange(item);
            },
            actions: [
              React.createElement(Button, {
                type: "link",
                icon: React.createElement(Icon, { icon: "ai:right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}} ),
                children: t("rs:search.select"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
              ),
            ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
          
            , React.createElement(List.Item.Meta, {
              title: item.get("title"),
              description: item.get("description"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
            )
          )
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      )
    );
  }
);
