const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\OfflineMessage.tsx";import { useTranslation } from "@opendash/core";
import React from "react";
import { ErrorMessage } from "..";

export function OfflineMessage() {
  const t = useTranslation();

  return (
    React.createElement(ErrorMessage, {
      icon: "fa:wifi-slash",
      title: t("rs:app.offline_title"),
      message: t("rs:app.offline_subtitle"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}
    )
  );
}
