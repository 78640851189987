import { useStorage } from "@opendash/core";
import moment from "moment";

export function useRideSearchDate() {
  return useStorage(
    "device",
    "ride-sharing:search-date",
    moment().startOf("day").valueOf()
  );
}
