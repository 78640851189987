import { useDebounce } from "@opendash/core";
import Parse from "parse";
import React from "react";
import { Location, useParseFunction } from "..";













export function useCloudCodeGeoCoding(query) {
  const queryDebounced = useDebounce(query, 500);

  const { result, error, loading } = useParseFunction(
    query ? "ride-sharing-geocode" : null,
    {
      query: queryDebounced,
      latitude: "50.872341",
      longitude: "8.015840",
    },
    [queryDebounced]
  );

  return React.useMemo(() => {
    return {
      error,
      loading,
      result: (result || []).map((result) => {
        return new Location({
          title: result.title,
          description: result.description,
          location: new Parse.GeoPoint(result.location),
        });
      }),
    };
  }, [result, error, loading]);
}
