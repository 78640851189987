const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\TaxiInfoList.tsx";import { useTranslation } from "@opendash/core";
import { Button, List, Modal } from "antd";
import * as Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React from "react";
import { ListEmpty, MetaIcon, TaxiInfo } from "..";

export function TaxiInfoList() {
  const t = useTranslation();
  // TODO: Query by postal code
  const query = React.useMemo(
    () => new Parse.Query(TaxiInfo).ascending("name").limit(3),
    []
  );

  const { result, loading } = useParseQuery(query);

  if (result.length === 0) {
    return React.createElement(ListEmpty, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}, t("rs:search.results.cap_offers_empty"));
  }

  return (
    React.createElement(List, {
      loading: loading,
      dataSource: result,
      renderItem: (taxi) => (
        React.createElement(List.Item, {
          actions: 
            taxi.get("phone")
              ? [
                  React.createElement(Button, {
                    icon: React.createElement(MetaIcon, { icon: "anrufen_waehlen", button: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}} ),
                    children: t("rs:search.results.cap_offers_call_action"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
                  ),
                ]
              : taxi.get("website")
              ? [
                  React.createElement(Button, {
                    icon: React.createElement(MetaIcon, { icon: "googeln", button: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}} ),
                    children: t("rs:search.results.cap_offers_web_action"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
                  ),
                ]
              : []
          ,
          onClick: () => {
            if (taxi.get("phone")) {
              window.open("tel:" + taxi.get("phone"));
            } else if (taxi.get("website")) {
              Modal.confirm({
                title: t("rs:app.open_external_confirm_title"),
                content: t("rs:app.open_external_confirm_description"),
                okText: t("rs:app.open_external_confirm_ok"),
                onOk() {
                  window.open(taxi.get("website"));
                },
              });
            }
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
        
          , React.createElement(List.Item.Meta, {
            title: taxi.get("name"),
            description: 
              taxi.get("phone")
                ? taxi.get("phone")
                : taxi.get("website")
                ? new URL(taxi.get("website")).host
                : null
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
          )
        )
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
    )
  );
}
