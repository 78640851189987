const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\UserPredefinedSearchList.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation, useUrlParam } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, Divider, Dropdown, List, Menu, message, Modal } from "antd";
import Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React from "react";
import styled from "styled-components";
import { FormatWeekdays, Search } from "..";


const Container = styled.div`
  padding: 24px 0;
`;

export function UserPredefinedSearchList({}) {
  const t = useTranslation();
  const [view, setView] = useUrlParam("view", null);

  const query = React.useMemo(
    () =>
      new Parse.Query(Search)
        .include("start")
        .include("end")
        .equalTo("user", Parse.User.current() ),
    []
  );

  const { result, loading, reload } = useParseQuery(query);

  React.useEffect(() => {
    if (!loading && result.length === 0) {
      setView("create");
    }
  }, [result, loading]);

  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
      , React.createElement(List, {
        loading: loading,
        dataSource: result,
        renderItem: (item) => {
          const onDelete = () => {
            Modal.confirm({
              title: t("rs:search.delete.confirm_title"),
              content: t("rs:search.delete.confirm_content"),
              okText: t("rs:search.delete.ok"),
              okType: "danger",
              onOk: () => {
                item.destroy().then(
                  (ok) => {
                    message.success(t("rs:search.delete.success"));
                    reload();
                  },
                  (error) => {
                    console.error(error);
                    message.success(t("rs:search.delete.error"));
                  }
                );
              },
            });
          };

          const menu = (
            React.createElement(Menu, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
              , React.createElement(Menu.Item, { key: "delete", onClick: onDelete, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
                , React.createElement(Icon, { icon: "ai:delete", __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}} )
                , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}, t("rs:search.delete.action"))
              )
              , React.createElement(Menu.Item, { key: "edit", disabled: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
                , React.createElement(Icon, { icon: "ai:edit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}} )
                , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}, t("rs:search.edit.action"))
              )
            )
          );

          return (
            React.createElement(List.Item, {
              actions: [
                React.createElement(Dropdown, {
                  overlay: menu,
                  trigger: ["click"],
                  children: 
                    React.createElement(Button, {
                      type: "default",
                      shape: "circle",
                      icon: React.createElement(Icon, { icon: "ai:more", __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
                    )
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
                ),
              ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
            
              , React.createElement(List.Item.Meta, {
                title: item.get("title"),
                description: 
                  React.createElement(React.Fragment, null
                    , React.createElement(FormatWeekdays, { days: item.get("weekdays"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}} )
                    , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}, " @ "  , item.get("time"))
                    , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 98}} )
                    , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}, `${
                      _optionalChain([item, 'access', _ => _.get, 'call', _2 => _2("start"), 'optionalAccess', _3 => _3.get, 'call', _4 => _4("title")]) ||
                      _optionalChain([item, 'access', _5 => _5.get, 'call', _6 => _6("start"), 'optionalAccess', _7 => _7.get, 'call', _8 => _8("description")])
                    } → ${
                      _optionalChain([item, 'access', _9 => _9.get, 'call', _10 => _10("end"), 'optionalAccess', _11 => _11.get, 'call', _12 => _12("title")]) ||
                      _optionalChain([item, 'access', _13 => _13.get, 'call', _14 => _14("end"), 'optionalAccess', _15 => _15.get, 'call', _16 => _16("description")])
                    }`)
                  )
                , __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
              )
            )
          );
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
      )
      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 113}} )
      , React.createElement('div', { style: { textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
        , React.createElement(Button, {
          type: "primary",
          size: "large",
          children: t("rs:search.create.action"),
          onClick: () => {
            setView("create");
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}
        )
      )
    )
  );
}
