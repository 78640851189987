const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\MessengerRoute.tsx";import { useTranslation, useUrlParam } from "@opendash/core";
import React from "react";
import { MessengerChat, MessengerList } from "..";

export function MessengerRoute({}) {
  const t = useTranslation();

  const [chat, setChat] = useUrlParam("chat", null, "string");

  if (chat) {
    return React.createElement(MessengerChat, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 11}} );
  }

  return React.createElement(MessengerList, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 14}} );
}
