const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\ErrorMessage.tsx";import { Icon } from "@opendash/icons";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
`;

const IconHolder = styled.div`
  font-size: 2em;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Message = styled.div`
  margin-bottom: 10px;
`;

export function ErrorMessage({
  icon,
  title,
  message,
}



) {
  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
      , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
        , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
          , React.createElement(Icon, { icon: icon, __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}} )
        )
        , React.createElement(Title, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}, title)
        , React.createElement(Message, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}, message)
      )
    )
  );
}
