 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { usePromise } from "@opendash/core";
import { translateSync } from "@opendash/i18n";
import moment from "moment";
import {

  useRideSearchDate,
  useRideSearchEnd,
  useRideSearchStart,
  useRideSearchTime,
} from "..";
import { fetchPublicTransport } from "../helper/fetchPublicTransport";

export function usePublicTransportRoute() {
  const [start] = useRideSearchStart();
  const [end] = useRideSearchEnd();
  const [date] = useRideSearchDate();
  const [time] = useRideSearchTime();

  return usePromise(() => {
    const [hour, minute] = time.split(":");

    const timestamp = moment(date)
      .hour(parseInt(hour))
      .minute(parseInt(minute));

    return fetchPublicTransportRoute(
      _optionalChain([start, 'optionalAccess', _ => _.get, 'call', _2 => _2("location"), 'access', _3 => _3.latitude]),
      _optionalChain([start, 'optionalAccess', _4 => _4.get, 'call', _5 => _5("location"), 'access', _6 => _6.longitude]),
      _optionalChain([end, 'optionalAccess', _7 => _7.get, 'call', _8 => _8("location"), 'access', _9 => _9.latitude]),
      _optionalChain([end, 'optionalAccess', _10 => _10.get, 'call', _11 => _11("location"), 'access', _12 => _12.longitude]),
      timestamp
    );
  }, [start, end, date, time]);
}

async function fetchPublicTransportRoute(
  originLat,
  originLong,
  destinationLat,
  destinationLong,
  arrivalTime
) {
  const response = await fetchPublicTransport("/journeys", {
    "from.type": "location",
    "from.address": "Start",
    "from.latitude": originLat.toString(),
    "from.longitude": originLong.toString(),
    "to.type": "location",
    "to.address": "Ziel",
    "to.latitude": destinationLat.toString(),
    "to.longitude": destinationLong.toString(),
    arrival: arrivalTime.toJSON(),
    language: "de",
  });

  const result = response.journeys
    .filter((v, i) => i < 3)
    .map((journey) => {
      const firstLeg = _optionalChain([journey, 'optionalAccess', _13 => _13.legs, 'optionalAccess', _14 => _14[0]]);
      const lastLeg = _optionalChain([journey, 'optionalAccess', _15 => _15.legs, 'optionalAccess', _16 => _16[_optionalChain([journey, 'optionalAccess', _17 => _17.legs, 'optionalAccess', _18 => _18.length]) - 1]]);

      const departureMoment = moment(firstLeg.departure);
      const arrivalMoment = moment(lastLeg.arrival);

      const departureTime = departureMoment.format("HH:mm");
      const arrivalTime = arrivalMoment.format("HH:mm");
      const durationInMinutes = arrivalMoment.diff(departureMoment, "minutes");

      const duration = translateSync(
        "rs:search.results.public_transport_duration",
        { count: durationInMinutes }
      );

      const lines = [];
      const stations = [];
      const stops = [];

      journey.legs.forEach((leg) => {
        const last_station = stations[stations.length - 1];

        const origin =
          leg.origin.type === "stop"
            ? leg.origin.name
            : leg.origin.type === "station"
            ? leg.origin.name
            : leg.origin.type === "location"
            ? leg.origin.address
            : "-";

        const destination =
          leg.destination.type === "stop"
            ? leg.destination.name
            : leg.destination.type === "station"
            ? leg.destination.name
            : leg.destination.type === "location"
            ? leg.destination.address
            : "-";

        if (leg.line) {
          if (last_station !== origin) {
            stations.push(origin);
          }

          stations.push(destination);

          lines.push(leg.line.name);

          stops.push({
            type: leg.line.product,
            title: leg.line.name,
            description: `${origin} →  ${destination}`,
          });
        }

        if (leg.walking) {
          stops.push({
            type: "walk",
            title: `Laufen`,
            description: `${leg.distance}m`,
          });
        }
      });

      return {
        title: `${lines.join(", ")} um ${departureTime} (${duration})`,
        description: stations.join(" → "),
        stops,
      };
    });

  return result;
}
