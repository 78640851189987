export default true;

import { Workbox } from "workbox-window";

if ("serviceWorker" in navigator) {
  const wb = new Workbox("/service-worker.js");

  wb.addEventListener("waiting", (event) => {
    if (window.confirm("App Update")) {
      wb.addEventListener("controlling", (event) => {
        window.location.reload();
      });

      wb.messageSW({ type: "SKIP_WAITING" });
    }
  });

  wb.register();
}

// if ("serviceWorker" in navigator) {
//   let registration: ServiceWorkerRegistration | null = null;

//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register("/service-worker.js")
//       .then((newRegistration) => {
//         registration = newRegistration;

//         console.log(`[@opendash/plugin-pwa] SW registration:`, registration);
//       })
//       .catch((registrationError) => {
//         console.error(
//           "[@opendash/plugin-pwa] SW registration failed: ",
//           registrationError
//         );
//       });
//   });

//   navigator.serviceWorker.addEventListener("message", (event) => {
//     console.log(`[@opendash/plugin-pwa] SW message:`, event);
//   });

//   navigator.serviceWorker.addEventListener("waiting", (event) => {
//     console.log(`[@opendash/plugin-pwa] SW waiting:`, event);

//     if (registration && window.confirm("App Update")) {
//       registration.waiting.postMessage({ type: "SKIP_WAITING" });
//     }
//   });

//   navigator.serviceWorker.addEventListener("controlling", (event) => {
//     console.log(`[@opendash/plugin-pwa] SW controlling:`, event);
//     window.location.reload();
//   });
// }
